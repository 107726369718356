import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { useIntl } from 'context/IntlContext';
import SEO from 'components/SEO';
import * as styles from './page.module.css';

const Page = ({
  data: {
    site: { siteMetadata },
    graphCMS: { page },
  },
}) => {
  const { t } = useIntl();
  const { title, texts, description } = page;
  const sectionLinks = texts.map(({ name, id, slug }) => (
    <li key={id}>
      <a href={`#${slug}`}>{name}</a>
    </li>
  ));
  const sections = texts.map((r) => {
    const { content, id, slug } = r;
    return (
      <section className={styles.Section} key={`section-${id}`} id={slug}>
        <ReactMarkdown children={content} />
      </section>
    );
  });
  return (
    <>
      <SEO
        title={t('page.meta.title')}
        keywords={siteMetadata.keywords}
        description={t('page.meta.description')}
      />

      <div className={styles.Container}>
        <article>
          <h1>{title}</h1>
          <hr />
          {/* <ol className={styles.SectionLinksContainer}>{sectionLinks}</ol> */}
          {description && (
            <div>
              <ReactMarkdown children={description} />
            </div>
          )}

          <div>{sections}</div>
        </article>
      </div>
    </>
  );
};

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    graphCMS {
      page(where: { slug: $slug }) {
        slug
        title
        description
        texts {
          ... on GraphCMS_Text {
            id
            name
            slug
            stage
            content
          }
        }
      }
    }
    site {
      siteMetadata {
        keywords
      }
    }
  }
`;

export default Page;
